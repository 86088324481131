import { CountryRegionData } from 'react-country-region-selector';
import { IPostTime, ISetting, IPost, Fee, IPostFilterKind, IBid, ColorPair } from '../types/Post';
import { ICurrency } from '../types/Currency';
import { FreeAds, FreeAdsUsed, IUser } from '../types/User';
import moment from 'moment';
import { ITransaction, TxType, TxStatus } from '../types/Transaction';
import { ICategory } from '../types/Category';
import { IStoreType } from '../types/Store';
import { IPageTemplate } from '../types/PageTemplate';
import { ProductStatus, IFilter, Kind } from '../types/Filter';
import { Review } from '../types/Review';
import callApi from '../utils/api';

export const MISSING_IMAGE = `${process.env.PUBLIC_URL}/images/bee.png`;
export const BEEDIT_QR = `${process.env.PUBLIC_URL}/images/beedit-qr-b.png`;
export const BEEDIT_PRESENT = `${process.env.PUBLIC_URL}/images/beedit-present.png`;
export const NO_IMAGE = `${process.env.PUBLIC_URL}/images/bee.png`;
export const BEEDITUP_BOTTOM = `${process.env.PUBLIC_URL}/images/beeditup_bottom.png`;
export const DEMO_BOTTOM = `${process.env.PUBLIC_URL}/images/demo_label.png`;
export const BEEDITUP_WON = `${process.env.PUBLIC_URL}/images/beeditup_won.png`;
export const SET_TP = {
    he: `${process.env.PUBLIC_URL}/images/set-tp-he.png`,
    en: `${process.env.PUBLIC_URL}/images/set-tp-en.png`,
};

export const PRODUCT_STATUS = ['New', 'Old', 'Broken'];
export const COUNTRIES = CountryRegionData.map((country: any) => country[0]) as string[];
export const COUNTRIES_OPTIONS: [] = CountryRegionData.map((country: any) => {
    return { label: country[0], value: country[0] };
});
export const SALE_LOCATIONS = ['Local Only', 'Worldwide', 'By Countries'];
export const DELIVERY_OPTIONS = ['Free', 'No Delivery', 'With Fee'];
export const AD_TYPES = ['beediT', 'Beed Auction'];
export const BALANCE_CHANGE_TYPES = ['topup', 'withdraw'];
export const AIRPORT_TRIP_TYPES = ['oneway', 'round'];
export const DELIVERY_LOCATIONS = ['Local Only', 'Worldwide', 'By Countries'];
export const AD_FRAME_COLORS = ['None', 'Blue', 'Red', 'Yellow', 'Purple', 'Green', 'Black'];

export const SocketEvents = {
    newPosts: 'listennew',
    listenPost: (postId: string) => 'listenpost_' + postId,
    listenMessages: (userId: string) => 'listenmessages_' + userId,
};

export const isDarkMode = document.body.classList.contains('dark-mode');

export function getColorPairStyle(cp: ColorPair, dm: boolean) {
    if (!cp) return '0';
    if (dm) return cp.dark;
    return cp.light;
}

export function getImageUrl(imageURI: string, type = 'ads', isFull = false) {
    if (imageURI.startsWith('http') || imageURI.startsWith('data')) return imageURI;
    return `${process.env.REACT_APP_API_ENDPOINT}/uploads_${type}/${isFull ? 'full/' : ''}${imageURI}`;
}

export function getRatings(
    feedbacks?: Review[],
): {
    ratingPrice: number;
    ratingService: number;
    ratingProduct: number;
    ratingDelivery: number;
    ratingCommunication: number;
} {
    const ratings: {
        ratingPrice: number;
        ratingService: number;
        ratingProduct: number;
        ratingDelivery: number;
        ratingCommunication: number;
    } = {
        ratingPrice: 0,
        ratingService: 0,
        ratingProduct: 0,
        ratingDelivery: 0,
        ratingCommunication: 0,
    };
    if (!feedbacks || feedbacks.length === 0) return ratings;
    const totalPrice = { count: 0, total: 0 };
    const totalService = { count: 0, total: 0 };
    const totalProduct = { count: 0, total: 0 };
    const totalDelivery = { count: 0, total: 0 };
    const totalCommunication = { count: 0, total: 0 };
    feedbacks.forEach((fb) => {
        if (fb.ratingPrice) {
            totalPrice.count += 1;
            totalPrice.total += fb.ratingPrice;
        }
        if (fb.ratingService) {
            totalService.count += 1;
            totalService.total += fb.ratingService;
        }
        if (fb.ratingProduct) {
            totalProduct.count += 1;
            totalProduct.total += fb.ratingProduct;
        }
        if (fb.ratingDelivery) {
            totalDelivery.count += 1;
            totalDelivery.total += fb.ratingDelivery;
        }
        if (fb.ratingCommunication) {
            totalCommunication.count += 1;
            totalCommunication.total += fb.ratingCommunication;
        }
    });
    ratings.ratingPrice = totalPrice.count > 0 ? totalPrice.total / totalPrice.count : 0;
    ratings.ratingService = totalService.count > 0 ? totalService.total / totalService.count : 0;
    ratings.ratingProduct = totalProduct.count > 0 ? totalProduct.total / totalProduct.count : 0;
    ratings.ratingDelivery = totalDelivery.count > 0 ? totalDelivery.total / totalDelivery.count : 0;
    ratings.ratingCommunication =
        totalCommunication.count > 0 ? totalCommunication.total / totalCommunication.count : 0;

    return ratings;
}

export function getFeedbacks(
    feedbacks?: Review[],
    reviewers?: IUser[],
): { fTotal: number; fCount: number; fAvg: number; feedbacks?: Review[] } {
    let total = 0;
    let count = 0;
    if (!feedbacks || feedbacks.length === 0) return { fTotal: 0, fCount: 0, fAvg: 0, feedbacks };
    feedbacks.forEach((fb) => {
        if (reviewers) fb.user = reviewers.find((usr) => usr._id === fb.createdBy);
        total += fb.ratingPrice ? fb.ratingPrice : 0;
        total += fb.ratingService ? fb.ratingService : 0;
        total += fb.ratingProduct ? fb.ratingProduct : 0;
        total += fb.ratingDelivery ? fb.ratingDelivery : 0;
        total += fb.ratingCommunication ? fb.ratingCommunication : 0;
        count += 1;
    });
    return { fTotal: total, fCount: count, fAvg: total / count, feedbacks };
}

export function getCurrencySymbol(currency: string) {
    const a = 0;
    const symbol = a
        .toLocaleString('en', { style: 'currency', currency: currency })
        .replace(/\d+([,.]\d+)?/g, '')
        .trimEnd();
    return symbol === currency ? '' : symbol;
}

function localizeText(en: string, he: string, language: string) {
    if (language.substring(0, 2) === 'he' && he && he.length > 0) return he;
    return en;
}

export function getLocalizedCategory(category: ICategory, language: string) {
    const en = category.title_en;
    const he = category.title_he;

    return localizeText(en, he, language);
}

export function getLocalizedText(translation: any, language: string) {
    const en = translation.en;
    const he = translation.he;

    return localizeText(en, he, language);
}

export function getLocalizedStoreAdvantage(storeType: IStoreType, language: string) {
    const en = storeType.advantage_info_en;
    const he = storeType.advantage_info_he;
    return localizeText(en, he, language);
}

export function getLocalizedInfoPageContent(pageTemplate: IPageTemplate, language: string) {
    const en = pageTemplate.htmlContent_en;
    const he = pageTemplate.htmlContent_he;
    return localizeText(en, he, language);
}

export function getLocalizedInfoPageTitle(pageTemplate: IPageTemplate, language: string) {
    const en = pageTemplate.title_en;
    const he = pageTemplate.title_he;
    return localizeText(en, he, language);
}

export function getLocalizedStatus(status: ProductStatus | IFilter | Kind, language: string) {
    const en = status.name_en;
    const he = status.name_he;
    return localizeText(en, he, language);
}

export function validateEmail(email: string) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function checkWeenItEnabled(
    user: IUser,
    settings: ISetting,
    type: 'add' | 'participate' | 'quick_timer',
): boolean {
    let isEnabled = true;

    if (settings['weenit_enabled'] !== 'true') isEnabled = false;
    else {
        if (
            settings[`weenit_allowed_admin_${type}`] !== 'true' &&
            (user.role === 'ADMIN' || user.role === 'SUPERADMIN')
        )
            isEnabled = false;
        if (settings[`weenit_allowed_customer_${type}`] !== 'true' && user.role === 'PERSONAL') isEnabled = false;
        if (settings[`weenit_allowed_business_${type}`] !== 'true' && user.role === 'BUSINESS') isEnabled = false;
        if (settings[`weenit_allowed_partner_${type}`] !== 'true' && user.role === 'PARTNER') isEnabled = false;
        if (settings[`weenit_allowed_pb_user_${type}`] !== 'true' && user.role === 'PB USER') isEnabled = false;
    }

    // console.log(settings, user, isEnabled);
    return isEnabled;
}

export function checkSelfStartEnabled(user: IUser, settings: ISetting): boolean {
    let isEnabled = true;
    if (settings['self_start_enabled'] !== 'true') isEnabled = false;
    else {
        if (settings[`self_start_admin`] !== 'true' && (user.role === 'ADMIN' || user.role === 'SUPERADMIN'))
            isEnabled = false;
        if (settings[`self_start_customer`] !== 'true' && user.role === 'PERSONAL') isEnabled = false;
        if (settings[`self_start_business`] !== 'true' && user.role === 'BUSINESS') isEnabled = false;
        if (settings[`self_start_partner`] !== 'true' && user.role === 'PARTNER') isEnabled = false;
        if (settings[`self_start_pb_user`] !== 'true' && user.role === 'PB USER') isEnabled = false;
    }
    // console.log(settings, user, isEnabled);
    return isEnabled;
}

export function checkBeeditWayEnabled(user: IUser, settings: ISetting): boolean {
    let isEnabled = true;
    if (settings['beedit_way_enabled'] !== 'true') isEnabled = false;
    else {
        if (settings[`beedit_way_allowed_admin`] !== 'true' && (user.role === 'ADMIN' || user.role === 'SUPERADMIN'))
            isEnabled = false;
        if (settings[`beedit_way_allowed_customer`] !== 'true' && user.role === 'PERSONAL') isEnabled = false;
        if (settings[`beedit_way_allowed_business`] !== 'true' && user.role === 'BUSINESS') isEnabled = false;
        if (settings[`beedit_way_allowed_partner`] !== 'true' && user.role === 'PARTNER') isEnabled = false;
        if (settings[`beedit_way_allowed_pb_user`] !== 'true' && user.role === 'PB USER') isEnabled = false;
    }
    // console.log(settings, user, isEnabled);
    return isEnabled;
}

export function checkApiEnabled(user: IUser, settings: ISetting): boolean {
    let isEnabled = true;
    if (settings['api_access'] !== 'true') isEnabled = false;
    else {
        if (settings[`api_allowed_admin`] !== 'true' && (user.role === 'ADMIN' || user.role === 'SUPERADMIN'))
            isEnabled = false;
        if (settings[`api_allowed_customer`] !== 'true' && user.role === 'PERSONAL') isEnabled = false;
        if (settings[`api_allowed_business`] !== 'true' && user.role === 'BUSINESS') isEnabled = false;
        if (settings[`api_allowed_partner`] !== 'true' && user.role === 'PARTNER') isEnabled = false;
        if (settings[`api_allowed_pb_user`] !== 'true' && user.role === 'PB USER') isEnabled = false;
    }

    // console.log(settings, user, isEnabled);
    return isEnabled;
}

export function checkBeeditUpEnabled(user: IUser, settings: ISetting): boolean {
    let isEnabled = true;
    if (settings['upper_timer_enabled'] !== 'true') isEnabled = false;
    else {
        if (settings[`upper_timer_allowed_admin`] !== 'true' && (user.role === 'ADMIN' || user.role === 'SUPERADMIN'))
            isEnabled = false;
        if (settings[`upper_timer_allowed_customer`] !== 'true' && user.role === 'PERSONAL') isEnabled = false;
        if (settings[`upper_timer_allowed_business`] !== 'true' && user.role === 'BUSINESS') isEnabled = false;
        if (settings[`upper_timer_allowed_partner`] !== 'true' && user.role === 'PARTNER') isEnabled = false;
        if (settings[`upper_timer_allowed_pb_user`] !== 'true' && user.role === 'PB USER') isEnabled = false;
    }

    // console.log(settings, user, isEnabled);
    return isEnabled;
}

export function checkPresentationEnabled(user: IUser, settings: ISetting): boolean {
    let isEnabled = true;
    if (settings[`presentation_allowed_admin`] !== 'true' && (user.role === 'ADMIN' || user.role === 'SUPERADMIN'))
        isEnabled = false;
    if (settings[`presentation_allowed_customer`] !== 'true' && user.role === 'PERSONAL') isEnabled = false;
    if (settings[`presentation_allowed_business`] !== 'true' && user.role === 'BUSINESS') isEnabled = false;
    if (settings[`presentation_allowed_partner`] !== 'true' && user.role === 'PARTNER') isEnabled = false;
    if (settings[`presentation_allowed_pb_user`] !== 'true' && user.role === 'PB USER') isEnabled = false;

    // console.log(settings, user, isEnabled);
    return isEnabled;
}

export const QUICK_SALE_OPTIONS = [
    { label: 'Win iT', color: 'red', isWeenit: true },
    { label: '10 Sec', color: 'red', isWeenit: true },
    { label: '30 Sec', color: 'red', isWeenit: true },
    { label: '1 Min', color: 'red', isWeenit: true },
    { label: '2 Min', color: 'red', isWeenit: true },
    { label: '3 Min', color: '#ba68c8', isWeenit: false },
    { label: '5 Min', color: '#ba68c8', isWeenit: false },
    { label: '10 Min', color: '#ba68c8', isWeenit: false },
    { label: '20 Min', color: '#ba68c8', isWeenit: false },
    { label: '30 Min', color: '#ba68c8', isWeenit: false },
    { label: '45 Min', color: '#ba68c8', isWeenit: false },
    { label: '60 Min', color: '#ba68c8', isWeenit: false },
];

export const quickSaleOptions = (withWeenIt: boolean, isFreeBee: boolean) => {
    if (withWeenIt) return QUICK_SALE_OPTIONS;
    else return QUICK_SALE_OPTIONS.filter((val) => !val.isWeenit);
};

export function checkTimeLabel(time: IPostTime, isFreeBee: boolean) {
    const options = QUICK_SALE_OPTIONS;
    if (!time) return null;
    if (!time.days || time.days == 0) {
        if (time.hours == 0 && time.minutes == 0 && time.seconds == 0) {
            return options[0];
        }
        if (time.hours == 0 && time.minutes == 0 && time.seconds == 10) {
            return options[1];
        }
        if (time.hours == 0 && time.minutes == 0 && time.seconds == 30) {
            return options[2];
        }
        if (time.hours == 0 && time.minutes == 1 && time.seconds == 0) {
            return options[3];
        }
        if (time.hours == 0 && time.minutes == 2 && time.seconds == 0) {
            return options[4];
        }
        if (time.hours == 0 && time.minutes == 3 && time.seconds == 0) {
            return options[5];
        }
        if (time.hours == 0 && time.minutes == 5 && time.seconds == 0) {
            return options[6];
        }
        if (time.hours == 0 && time.minutes == 10 && time.seconds == 0) {
            return options[7];
        }
        if (time.hours == 0 && time.minutes == 20 && time.seconds == 0) {
            return options[8];
        }
        if (time.hours == 0 && time.minutes == 30 && time.seconds == 0) {
            return options[9];
        }
        if (time.hours == 0 && time.minutes == 45 && time.seconds == 0) {
            return options[10];
        }
        if ((time.hours && time.hours == 1 && !time.minutes) || (time.minutes == 0 && time.seconds == 0)) {
            return options[11];
        }
    }
    return null;
}

// export function effectClass(effectName: string){
//     switch (effectName) {
//         case "Stars": return 'starshine';
//         case "Hearts": return 'starshine2';
//         case "Bee": return 'starshine3';
//         case "Bling Bling": return 'starshine4';
//         // case "HOT": return 'starshine';

//         default: return '';
//     }
// }

export function getRateValue(rates: ICurrency, curRate: string) {
    if (Object.keys(rates).length > 0) return rates[curRate];
    return 1;
}

export function applyRate(
    rates: ICurrency,
    rateFrom: string | undefined | null,
    rateTo: string | undefined | null,
    value: number,
    isNum = false,
): number | string {
    if (!rateFrom || rateFrom === '' || rateFrom === null) rateFrom = 'USD';
    if (!rateTo || rateTo === '' || rateTo === null) rateTo = 'USD';
    try {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: rateTo,
        });
        if (!value) return isNum ? 0.0 : formatter.format(0);
        if (rateTo === rateFrom) return isNum ? value : formatter.format(value);
        if (Object.keys(rates).length === 0) return isNum ? value : formatter.format(value);

        const usdRateFrom = rates[rateFrom];
        const usdRateTo = rates[rateTo];
        value = value * (usdRateTo / usdRateFrom);
        return isNum ? Math.round(value * 100) / 100 : formatter.format(value);
    } catch (err) {
        console.warn(err, rateFrom, rateTo);
    }
    return 0;
}

export function usdToRate(rateValue: number, curRate: string, value: number): number {
    if (!value) return 0.0;
    if (curRate === 'USD') return value;
    // if (Object.keys(rates).length > 0)
    //     for (const cr of rates) {
    //         if (cr.name === curRate) {
    //             value = value * cr.rate;
    //             break;
    //         }
    //     }
    value = value * rateValue;
    return Math.round(value * 100) / 100;
}

export function rateToUsd(rateValue: number, curRate: string, value: number): number {
    if (!value) return 0.0;
    if (curRate === 'USD') return value;
    // if (Object.keys(rates).length > 0)
    //     for (const cr of rates) {
    //         if (cr.name === curRate) {
    //             value = value / cr.rate;
    //             break;
    //         }
    //     }
    value = value / rateValue;
    return Math.round(value * 100) / 100;
}

export function frameColorClass(frameColor: string) {
    switch (frameColor) {
        case 'Blue':
            return 'blueframe';
        case 'Red':
            return 'redframe';
        case 'Yellow':
            return 'yellowframe';
        case 'Purple':
            return 'purpleframe';
        case 'Green':
            return 'greenframe';
        case 'Black':
            return 'blackframe';
        // case "HOT": return 'starshine';

        default:
            return '';
    }
}

export function getSetting(settings: ISetting, key: string) {
    return settings[key];
}

export function checkIfBidded(user: IUser, post: IPost) {
    if (!post.bids) return false;
    const bids = post.bids;
    // console.log(bids.filter((b) => b.bidder_id === user.id));
    return bids.filter((b) => b.bidder_id === user.id).length > 0;
}

export function getUserBidsForPost(user: IUser, post: IPost) {
    if (!post.last_bid) return [];
    return user.bids.filter((bid) => bid.post_id === post._id);
}

export function checkIfDeposited(user: IUser, post: IPost) {
    return (
        user.transactions.filter(
            (tx) =>
                tx.item === post._id &&
                tx.type === TxType.BID_POST &&
                (tx.status === TxStatus.PENDING || tx.status === TxStatus.FINISHED),
        ).length > 0
    );
}

export async function getAirportData(name: string, isFull = false) {
    return await callApi(
        'get',
        process.env.REACT_APP_API_ENDPOINT || '',
        `/category/airport/${name}${isFull ? '/full' : ''}`,
        '',
    );
}

export function calculateDepositFee(
    settings: ISetting,
    postPrice: { min: number; max: number },
    isFreeBee = false,
    beeditUp = false,
    type: 'buyer' | 'seller' | 'partner' | 'pb user' | 'travel' | 'pb travel',
): {
    feePerc: number | undefined;
    price: number;
    deposit: number;
    maxDeposit: number;
    percFrom?: number;
    percTo?: number;
    minFee?: number;
    maxFee?: number;
    freeBeeFixedFee?: number;
} {
    const depositFee = 0;
    let maxDeposit = 1000;
    let freeBeeFixedFee = 0;
    let fees: Fee[] = [];

    let fee_table_fixed_down: Fee | undefined;
    let fee_table_fixed_up: Fee | undefined;
    let fee_table_beedit_up_fixed_down: Fee | undefined;
    let fee_table_beedit_up_fixed_up: Fee | undefined;

    if (settings['fee_table_fixed_down']) fee_table_fixed_down = JSON.parse(settings['fee_table_fixed_down']);
    if (settings['fee_table_fixed_up']) fee_table_fixed_up = JSON.parse(settings['fee_table_fixed_up']);
    if (settings['fee_table_beedit_up_fixed_down'])
        fee_table_beedit_up_fixed_down = JSON.parse(settings['fee_table_beedit_up_fixed_down']);
    if (settings['fee_table_beedit_up_fixed_up'])
        fee_table_beedit_up_fixed_up = JSON.parse(settings['fee_table_beedit_up_fixed_up']);

    if (settings['fee_table']) fees = JSON.parse(settings['fee_table']);
    if (settings['fee_threshold']) maxDeposit = parseInt(settings['fee_threshold']);
    if (settings['freebee_fixed_fee'] && isFreeBee) freeBeeFixedFee = parseFloat(settings['freebee_fixed_fee']);

    let firstFeeStep: Fee | undefined;
    let curFeeStep: Fee | undefined;
    let prevFeeStep: Fee | undefined;
    // let nextFeeStep: Fee | undefined;
    let isLast = false;
    for (let index = 0; index < fees.length; index++) {
        const step = fees[index];
        if (!firstFeeStep && step) firstFeeStep = step;
        if (step.from <= postPrice.max) {
            curFeeStep = step;
            if (index < fees.length - 1) {
                prevFeeStep = index > 0 ? fees[index - 1] : firstFeeStep;
                // nextFeeStep = fees[index + 1];
            }
            if (index === fees.length - 1) isLast = true;
        }
    }
    if (!curFeeStep) {
        if (fee_table_fixed_down && fee_table_beedit_up_fixed_down) {
            let fixedPrice = 0;
            if (type === 'buyer')
                fixedPrice = !beeditUp ? fee_table_fixed_down.buyer : fee_table_beedit_up_fixed_down.buyer;
            if (type === 'seller')
                fixedPrice = !beeditUp ? fee_table_fixed_down.seller_perc : fee_table_beedit_up_fixed_down.seller_perc;
            if (type === 'partner')
                fixedPrice = !beeditUp
                    ? fee_table_fixed_down.partner_perc
                    : fee_table_beedit_up_fixed_down.partner_perc;
            if (type === 'pb user')
                fixedPrice = !beeditUp
                    ? fee_table_fixed_down.pb_user_perc
                    : fee_table_beedit_up_fixed_down.pb_user_perc;
            if (type === 'travel')
                fixedPrice = !beeditUp ? fee_table_fixed_down.travel_perc : fee_table_beedit_up_fixed_down.travel_perc;
            if (type === 'pb travel')
                fixedPrice = !beeditUp
                    ? fee_table_fixed_down.pb_travel_perc
                    : fee_table_beedit_up_fixed_down.pb_travel_perc;
            if (type !== 'buyer') {
                const minThreshold = postPrice.min + fixedPrice;
                if (minThreshold > postPrice.max) {
                    fixedPrice = postPrice.max - postPrice.min;
                }
            }
            return {
                feePerc: undefined,
                price: fixedPrice,
                deposit: fixedPrice,
                maxDeposit,
            };
        }
        return { feePerc: 0, price: 0, deposit: 0, maxDeposit };
    }
    if (type === 'buyer') {
        let buyerDeposit = 0;
        if (curFeeStep && prevFeeStep) {
            buyerDeposit = isFreeBee ? freeBeeFixedFee : (curFeeStep.buyer + prevFeeStep.buyer) / 2;
            if (isLast) {
                if (fee_table_fixed_up && fee_table_beedit_up_fixed_up) {
                    const fixedPrice = !beeditUp ? fee_table_fixed_up.buyer : fee_table_beedit_up_fixed_up.buyer;

                    return {
                        feePerc: undefined,
                        price: fixedPrice,
                        deposit: fixedPrice,
                        maxDeposit,
                    };
                }
            }
        }
        return { feePerc: undefined, price: buyerDeposit, deposit: buyerDeposit, maxDeposit, freeBeeFixedFee };
    }
    if (
        (type === 'seller' || type === 'partner' || type === 'pb user' || type === 'travel' || type === 'pb travel') &&
        firstFeeStep
    ) {
        const priceFrom = curFeeStep.from;
        const priceTo = 0;
        let percFrom = 0;
        let percTo =
            type === 'partner' && firstFeeStep.partner_perc ? firstFeeStep.partner_perc : firstFeeStep.seller_perc;
        if (type === 'pb user') percTo = firstFeeStep.pb_user_perc;

        if (type === 'travel') percTo = firstFeeStep.travel_perc;
        if (type === 'pb travel') percTo = firstFeeStep.pb_travel_perc;

        if (curFeeStep.from === 0) {
            percFrom =
                type === 'partner' && firstFeeStep.partner_perc ? firstFeeStep.partner_perc : firstFeeStep.seller_perc;
            percTo = type === 'partner' && curFeeStep.partner_perc ? curFeeStep.partner_perc : curFeeStep.seller_perc;
            if (type === 'pb user') percFrom = firstFeeStep.pb_user_perc;
            if (type === 'pb user') percTo = curFeeStep.pb_user_perc;

            if (type === 'travel') percFrom = firstFeeStep.travel_perc;
            if (type === 'travel') percTo = curFeeStep.travel_perc;
            if (type === 'pb travel') percFrom = firstFeeStep.pb_travel_perc;
            if (type === 'pb travel') percTo = curFeeStep.pb_travel_perc;

            const curPriceFrom = firstFeeStep.from;
            const curPriceTo = curFeeStep.from;

            const rangePrice = curPriceTo - curPriceFrom;
            const correctedStartValue = postPrice.max - curPriceFrom;
            const percPrice = correctedStartValue / rangePrice;

            if (percTo !== percFrom) {
                const rangePerc = percFrom - percTo;
                const percOfRange = percPrice * rangePerc;
                // console.log({
                //     curPriceFrom,
                //     curPriceTo,
                //     percTo,
                //     percFrom,
                //     percPrice,
                //     percOfRange,
                //     rangePrice,
                //     correctedStartValue,
                // });
                percTo = percFrom - percOfRange;
            }

            const minFee = postPrice.min * (percFrom / 100);
            const maxFee = postPrice.max * (percTo / 100);
            const finalPrice = postPrice.max * (depositFee / 100);
            let sellerDeposit = maxFee;
            const minThreshold = postPrice.min + sellerDeposit;
            if (minThreshold > postPrice.max) {
                sellerDeposit = postPrice.max - postPrice.min;
            }
            return {
                feePerc: percTo,
                price: finalPrice,
                deposit: sellerDeposit,
                percFrom,
                percTo,
                minFee,
                maxFee,
                maxDeposit,
            };
        }
        if (prevFeeStep && curFeeStep) {
            percFrom =
                type === 'partner' && prevFeeStep.partner_perc ? prevFeeStep.partner_perc : prevFeeStep.seller_perc;
            percTo = type === 'partner' && curFeeStep.partner_perc ? curFeeStep.partner_perc : curFeeStep.seller_perc;
            if (type === 'pb user') percFrom = prevFeeStep.pb_user_perc;
            if (type === 'pb user') percTo = curFeeStep.pb_user_perc;

            if (type === 'travel') percFrom = prevFeeStep.travel_perc;
            if (type === 'travel') percTo = curFeeStep.travel_perc;
            if (type === 'pb travel') percFrom = prevFeeStep.pb_travel_perc;
            if (type === 'pb travel') percTo = curFeeStep.pb_travel_perc;

            const curPriceFrom = prevFeeStep.from;
            const curPriceTo = curFeeStep.from;

            const rangePrice = curPriceTo - curPriceFrom;
            const correctedStartValue = postPrice.max - curPriceFrom;
            const percPrice = correctedStartValue / rangePrice;

            if (percTo !== percFrom) {
                const rangePerc = percFrom - percTo;
                const percOfRange = percPrice * rangePerc;
                // console.log({
                //     curPriceFrom,
                //     curPriceTo,
                //     percTo,
                //     percFrom,
                //     percPrice,
                //     percOfRange,
                //     rangePrice,
                //     correctedStartValue,
                // });
                if (percOfRange < percFrom) percTo = percFrom - percOfRange;
                else {
                    // Calculate fixed prices over last!
                    if (fee_table_fixed_up && fee_table_beedit_up_fixed_up) {
                        let fixedPrice = 0;
                        // if (type === 'buyer')
                        //     fixedPrice = !beeditUp ? fixed.fee_table_fixed_down.buyer : fixed.fee_table_beedit_up_fixed_down.buyer;
                        if (type === 'seller')
                            fixedPrice = !beeditUp
                                ? fee_table_fixed_up.seller_perc
                                : fee_table_beedit_up_fixed_up.seller_perc;
                        if (type === 'partner')
                            fixedPrice = !beeditUp
                                ? fee_table_fixed_up.partner_perc
                                : fee_table_beedit_up_fixed_up.partner_perc;
                        if (type === 'pb user')
                            fixedPrice = !beeditUp
                                ? fee_table_fixed_up.pb_user_perc
                                : fee_table_beedit_up_fixed_up.pb_user_perc;
                        if (type === 'travel')
                            fixedPrice = !beeditUp
                                ? fee_table_fixed_up.travel_perc
                                : fee_table_beedit_up_fixed_up.travel_perc;
                        if (type === 'pb travel')
                            fixedPrice = !beeditUp
                                ? fee_table_fixed_up.pb_travel_perc
                                : fee_table_beedit_up_fixed_up.pb_travel_perc;
                        const minThreshold = postPrice.min + fixedPrice;
                        if (minThreshold > postPrice.max) {
                            fixedPrice = postPrice.max - postPrice.min;
                        }
                        return {
                            feePerc: undefined,
                            price: fixedPrice,
                            deposit: fixedPrice,
                            maxDeposit,
                        };
                    }
                }
            }

            // console.log({ percTo, percFrom, percOfRange, rangePrice, correctedStartValue });

            const minFee = postPrice.min * (percFrom / 100);
            const maxFee = postPrice.max * (percTo / 100);
            const finalPrice = postPrice.max * (percFrom / 100);
            let sellerDeposit = maxFee;
            // adjust price to not be below profit
            const minThreshold = postPrice.min + sellerDeposit;
            if (minThreshold > postPrice.max) {
                sellerDeposit = postPrice.max - postPrice.min;
            }
            if (curFeeStep.from === priceTo)
                return {
                    feePerc: percFrom,
                    price: finalPrice,
                    deposit: sellerDeposit,
                    percFrom,
                    percTo,
                    minFee: minFee,
                    maxFee: maxFee,
                    maxDeposit,
                };
        } else {
            percFrom =
                type === 'partner' && firstFeeStep.partner_perc ? firstFeeStep.partner_perc : firstFeeStep.seller_perc;
            percTo = type === 'partner' && curFeeStep.partner_perc ? curFeeStep.partner_perc : curFeeStep.seller_perc;
            if (type === 'pb user') percFrom = firstFeeStep.pb_user_perc;
            if (type === 'pb user') percTo = curFeeStep.pb_user_perc;

            if (type === 'travel') percFrom = firstFeeStep.travel_perc;
            if (type === 'travel') percTo = curFeeStep.travel_perc;
            if (type === 'pb travel') percFrom = firstFeeStep.pb_travel_perc;
            if (type === 'pb travel') percTo = curFeeStep.pb_travel_perc;

            const curPriceFrom = firstFeeStep.from;
            const curPriceTo = curFeeStep.from;

            const rangePrice = curPriceTo - curPriceFrom;
            const correctedStartValue = postPrice.max - curPriceFrom;
            const percPrice = correctedStartValue / rangePrice;

            if (percTo === percFrom) {
                const rangePerc = percFrom - percTo;
                const percOfRange = percPrice * rangePerc;
                // console.log({
                //     curPriceFrom,
                //     curPriceTo,
                //     percTo,
                //     percFrom,
                //     percPrice,
                //     percOfRange,
                //     rangePrice,
                //     correctedStartValue,
                // });
                if (percOfRange < percFrom) percTo = percFrom - percOfRange;
            }

            const minFee = postPrice.min * (percFrom / 100);
            const maxFee = postPrice.max * (percTo / 100);
            let sellerDeposit = maxFee;
            const finalPrice = postPrice.max * (depositFee / 100);
            const minThreshold = postPrice.min + sellerDeposit;
            if (minThreshold > postPrice.max) {
                sellerDeposit = postPrice.max - postPrice.min;
            }
            return {
                feePerc: percTo,
                price: finalPrice,
                deposit: sellerDeposit,
                percFrom,
                percTo,
                minFee,
                maxFee,
                maxDeposit,
            };
        }
        let feePerc = 0;
        const rangePrice = priceTo - priceFrom;
        const correctedStartValue = postPrice.max - priceFrom;
        const percPrice = correctedStartValue / rangePrice;

        const rangePerc = percTo - percFrom;
        const percOfRange = percPrice * rangePerc;
        feePerc = percOfRange + percFrom;

        const minFee = postPrice.min * (percFrom / 100);
        const maxFee = postPrice.max * (percTo / 100);
        let sellerDeposit = maxFee;
        const finalPrice = postPrice.max * (feePerc / 100);

        const minThreshold = postPrice.min + finalPrice;
        if (minThreshold > postPrice.max) {
            sellerDeposit = postPrice.max - postPrice.min;
        }

        return {
            feePerc: feePerc,
            price: finalPrice,
            deposit: sellerDeposit,
            percFrom,
            percTo,
            minFee: minFee,
            maxFee: maxFee,
            maxDeposit,
        };
    }
    console.log('WRONG CALCULATIONS!');
    return { feePerc: undefined, price: 0, deposit: 0, maxDeposit };
}

export function setPageTitle(name: string) {
    document.title = name + ' - beediT';
}

export function formatMode(mode: string) {
    if (!mode) return '-';
    return (mode.charAt(0).toUpperCase() + mode.slice(1)).split('_').join(' ');
}

export function elegibleFreeAds(user: IUser): { daysLeft: number; adsLeft: number } {
    const freeAdsAmount = user.freeAdsAmount;
    const freeAdsBy = user.freeAdsBy;

    if (!freeAdsAmount || !freeAdsBy) return { daysLeft: 0, adsLeft: 0 };
    if (freeAdsAmount === 0) return { daysLeft: 0, adsLeft: 0 };
    // console.log(freeAdsAmount, freeAdsBy, moment(freeAdsBy).isSameOrBefore())
    if (moment(freeAdsBy).isSameOrBefore()) return { daysLeft: 0, adsLeft: 0 };
    else return { daysLeft: moment(freeAdsBy).diff(moment(), 'days'), adsLeft: freeAdsAmount };
}

export function calculateUsedFreeAds(freeAdsToUse: FreeAdsUsed[]) {
    let used = 0;
    let regular = 0;
    let onePlusOne = 0;
    let endlessly = 0;
    freeAdsToUse.forEach(cur=>{
        regular+=cur.regular;
        onePlusOne+=cur.onePlusOne;
        endlessly+=cur.endlessly;
    })
    used = regular + onePlusOne + endlessly;
    return used;
}

export function elegibleAutoFreeAds(
    settings: ISetting,
    user: IUser,
    post: IPost
): {
    visiblePriceStepIndexes: number[],
    availableFreeAds: FreeAds;
} {
    let autoFreeAds = user.autoFreeAds;
        if (!autoFreeAds) autoFreeAds = {
            regular: [0, 0, 0, 0],
            onePlusOne: [0, 0, 0, 0],
            endlessly: [0, 0, 0, 0],
        };
    const amounts = {
        visiblePriceStepIndexes: [] as number[],
        availableFreeAds: autoFreeAds
    };

    let isEnabled = true;

    let auto_free_post_enabled = false;
    // let auto_free_post_min = 6;
    let auto_free_post_allowed_admin = false;
    let auto_free_post_allowed_customer = false;
    let auto_free_post_allowed_business = false;
    let auto_free_post_allowed_partner = false;
    let auto_free_post_allowed_pb_user = false;
    let post_max_price = 30000;
    let post_max_price_2 = 60000;
    let post_max_price_3 = 100000;

    auto_free_post_enabled = settings['auto_free_post_enabled'] === 'true';
    // auto_free_post_min = parseInt(settings['auto_free_post_min']);
    auto_free_post_allowed_admin = settings['auto_free_post_allowed_admin'] === 'true';
    auto_free_post_allowed_customer = settings['auto_free_post_allowed_customer'] === 'true';
    auto_free_post_allowed_business = settings['auto_free_post_allowed_business'] === 'true';
    auto_free_post_allowed_partner = settings['auto_free_post_allowed_partner'] === 'true';
    auto_free_post_allowed_pb_user = settings['auto_free_post_allowed_pb_user'] === 'true';

    post_max_price = parseFloat(settings['post_max_price']);
    post_max_price_2 = parseFloat(settings['post_max_price_2']);
    post_max_price_3 = parseFloat(settings['post_max_price_3']);

    // if (salePriceUsd && salePriceUsd < auto_free_post_min) return amounts;
    if (!auto_free_post_enabled) isEnabled = false;
    else {
        if (!auto_free_post_allowed_admin && (user.role === 'ADMIN' || user.role === 'SUPERADMIN')) isEnabled = false;
        if (!auto_free_post_allowed_customer && user.role === 'PERSONAL') isEnabled = false;
        if (!auto_free_post_allowed_business && user.role === 'BUSINESS') isEnabled = false;
        if (!auto_free_post_allowed_partner && user.role === 'PARTNER') isEnabled = false;
        if (!auto_free_post_allowed_pb_user && user.role === 'PB USER') isEnabled = false;
    }
    if (user.autoFreeAdsEnabled === true) isEnabled = true;
    if (!isEnabled) return amounts;

    if (post.max_price < post_max_price) {
        amounts.visiblePriceStepIndexes = [0];
    } else if (post.max_price < post_max_price_2) {
        amounts.visiblePriceStepIndexes = [1];
    } else if (post.max_price < post_max_price_3) {
        amounts.visiblePriceStepIndexes = [2];
    } else {
        amounts.visiblePriceStepIndexes = [3];

    }

    return amounts;
}

export function checkPaymentState(bid: IBid) {
    let paymentState: 'unpaid' | 'completed' | 'chargedOnly' | 'shipped' | 'paidOnly' | 'refunded' = 'unpaid';
    if (!bid.transactions || !bid.final_tx) return paymentState;
    if (bid.transactions) {
        let isCharged = false;
        let isPaid = false;
        let isRefunded = false;
        for (const tx of bid.transactions) {
            if (tx.type === TxType.ORDER_CHARGE) isCharged = true;
            if (tx.type === TxType.ORDER_PAYMENT) isPaid = true;
            if (tx.type === TxType.ORDER_REFUND) isRefunded = true;
        }
        if (isRefunded) paymentState = 'refunded';
        else if (isCharged && isPaid && bid.is_shipped) paymentState = 'completed';
        else if (isCharged && !bid.is_shipped) paymentState = 'chargedOnly';
        else if (isCharged && bid.is_shipped) paymentState = 'shipped';
        else if (isPaid) paymentState = 'paidOnly';
    }
    return paymentState;
}
