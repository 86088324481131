import { action } from 'typesafe-actions';
import { UserActionTypes } from './types';
import { IUser, RegisterPromoCode } from '../../types/User';
import { ICurrency } from '../../types/Currency';
import { IBid } from '../../types/Post';
import { IOrder } from '../../types/Order';
import { Message } from '../../types/Message';
import { Review } from '../../types/Review';
import { IEmailTemplate } from '../../types/EmailTemplate';

// Here we use the `action` helper function provided by `typesafe-actions`.
// This library provides really useful helpers for writing Redux actions in a type-safe manner.
// For more info: https://github.com/piotrwitek/typesafe-actions
export const fetchUser = () => action(UserActionTypes.FETCH_USER);
export const loginError = () => action(UserActionTypes.LOGIN_ERROR);
export const updateUser = (user: IUser) => action(UserActionTypes.UPDATE_USER, user);
export const uploadAvatar = (image: File) => action(UserActionTypes.UPLOAD_AVATAR, image);
export const editFavorites = (fAction: string, postId: string) =>
    action(UserActionTypes.EDIT_FAVORITES, { fAction, postId });
export const fetchTransactions = () => action(UserActionTypes.FETCH_TRANSACTIONS);
export const fetchBids = () => action(UserActionTypes.FETCH_BIDS);
export const fetchOrder = (bid: IBid) => action(UserActionTypes.FETCH_ORDER, bid);
export const fetchedOrder = (order: IOrder) => action(UserActionTypes.FETCHED_ORDER, order);
export const balanceChange = (
    type: string,
    source: string,
    amount: number,
    confirmation_type: 'sms' | 'email' | 'none',
    confirmation_id?: string,
) => action(UserActionTypes.BALANCE_CHANGE, { type, source, amount, confirmation_type, confirmation_id });

export const chargeOrder = (bid: IBid, gatewayAction: '' | 'charge' | 'confirm' | 'ship' | 'confirmationNumber' = '') =>
    action(UserActionTypes.CHARGE_ORDER, { bid, gatewayAction });
export const requestOtp = () => action(UserActionTypes.REQUEST_OTP);
export const refundOrder = (bid: IBid) => action(UserActionTypes.REFUND_ORDER, bid);

export const leaveFeedback = (bid: IBid, review: Review) => action(UserActionTypes.LEAVE_FEEDBACK, { bid, review });
export const editFeedback = (bid: IBid, review: Review) => action(UserActionTypes.EDIT_FEEDBACK, { bid, review });

export const fetchSeller = (username: string) => action(UserActionTypes.FETCH_SELLER, username);
export const fetchedSeller = (seller: IUser) => action(UserActionTypes.FETCHED_SELLER, seller);

export const fetchRates = () => action(UserActionTypes.FETCH_RATES);
export const ratesFetched = (rates: ICurrency) => {
    // console.log('R', rates);
    return action(UserActionTypes.RATES_FETCHED, rates);
};

export const setRate = (name: string, rates: ICurrency) => action(UserActionTypes.RATE_SETTED, { name, rates });

export const fetchSuccess = (user: IUser | null) => action(UserActionTypes.FETCH_SUCCESS, user);
export const userUpdated = (user: IUser) => action(UserActionTypes.USER_UPDATED, user);
export const fetchError = (message: string) => action(UserActionTypes.FETCH_ERROR, message);

export const fetchMessages = () => action(UserActionTypes.FETCH_MESSAGES);
export const markMessagesViewed = (messages: Message[]) => action(UserActionTypes.VIEWED_MESSAGE, messages);
export const removeMessages = (messages: Message[]) => action(UserActionTypes.REMOVE_MESSAGE, messages);
export const fetchedMessages = (messages: Message[]) => action(UserActionTypes.FETCHED_MESSAGES, messages);
export const sendMessage = (message: Message) => action(UserActionTypes.SEND_MESSAGE, message);

export const listenNewMessagesSocket = (token: string, user: IUser) =>
    action(UserActionTypes.SOCKET_LISTENMESSAGE, { token, user });
export const listenNewMessagesSocketReceived = (message: Message) =>
    action(UserActionTypes.SOCKET_LISTENMESSAGE_RECEIVED, message);

export const createApi = () => action(UserActionTypes.CREATE_API);
export const revokeApi = () => action(UserActionTypes.REVOKE_API);

export const onFetchedEmailTemplates = (emailTemplates: IEmailTemplate[]) =>
    action(UserActionTypes.FETCHED_EMAILTEMPLATES, emailTemplates);
export const fetchEmailTemplates = () => action(UserActionTypes.FETCH_EMAILTEMPLATES);

export const createdApi = (apiKey?: string, apiKeyCreateDate?: Date) =>
    action(UserActionTypes.CREATED_API, { apiKey, apiKeyCreateDate });

export const validatePromo = (code: string) => action(UserActionTypes.VALIDATE_PROMO, code);
export const validatedPromo = (registerPromo: RegisterPromoCode) => action(UserActionTypes.VALIDATED_PROMO, registerPromo);
    
