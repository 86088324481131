import * as React from 'react';
import { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';

import Root from './components/layout/Root';
// import Login from './pages/Login';
// import Register from './pages/Register';
// import Main from './components/Main';
import * as hi from 'history';
import ReactGA from 'react-ga';
import { Loader } from './components/Loader';
// import ForgotPassword from './pages/ForgotPassword';

const Login = lazy(() => import('./pages/Login'));
const Register = lazy(() => import('./pages/Register'));
const Main = lazy(() => import('./components/Main'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));

export interface IProps {}

export interface IState {
    // wow: any,
}

const history = hi.createBrowserHistory();
history.listen((location) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
});

class Routes extends React.Component<IProps, IState> {
    public constructor(props: IProps) {
        super(props);
        this.state = {};
    }

    onLoad() {}

    public componentDidMount() {
        ReactGA.pageview(window.location.pathname);
    }

    public render() {
        return (
            <Root>
                <Suspense fallback={<Loader />}>
                    <Switch>
                        <Route
                            exact
                            path="/api-docs"
                            component={() => {
                                window.location.replace('/server/api-docs');
                                return null;
                            }}
                        />
                        <Route exact path="/login" component={Login} />
                        <Route exact path="/confirm/:confirmKey" component={Login} />
                        <Route exact path="/register" component={Register} />
                        <Route exact path="/forgot-password/:key?" component={ForgotPassword} />
                        <Route path="/" component={Main} />
                    </Switch>
                </Suspense>
            </Root>
        );
    }
}

export default Routes;
