import { IUser, RegisterPromoCode } from '../../types/User';
import { ICurrency } from '../../types/Currency';
import { IOrder } from '../../types/Order';
import { Message } from '../../types/Message';
import { IEmailTemplate } from '../../types/EmailTemplate';

export enum UserActionTypes {
    FETCH_USER = '@@user/FETCH_USER',
    UPDATE_USER = '@@user/UPDATE_USER',
    USER_UPDATED = '@@user/USER_UPDATED',
    UPLOAD_AVATAR = '@@user/UPLOAD_AVATAR',
    EDIT_FAVORITES = '@@user/EDIT_FAVORITES',
    FETCH_TRANSACTIONS = '@@tx/FETCH',
    BALANCE_CHANGE = '@@tx/BALANCE_CHANGE',
    REQUEST_OTP = '@@user/REQUEST_OTP',
    FETCH_RATES = '@@user/FETCH_RATES',
    RATES_FETCHED = '@@user/RATES_FETCHED',
    RATE_SETTED = '@@rate/RATE_SETTED',
    FETCH_BIDS = '@@bids/FETCH',
    FETCH_SUCCESS = '@@user/FETCH_SUCCESS',
    LOGIN_ERROR = '@@user/LOGIN_ERROR',
    FETCH_ERROR = '@@user/FETCH_ERROR',

    FETCH_ORDER = '@@order/FETCH_ORDER',
    FETCHED_ORDER = '@@order/FETCHED_ORDER',

    CHARGE_ORDER = '@@order/CHARGE',
    REFUND_ORDER = '@@order/REFUND',

    LEAVE_FEEDBACK = '@@order/LEAVE_FEEDBACK',
    EDIT_FEEDBACK = '@@order/EDIT_FEEDBACK',

    FETCH_MESSAGES = '@@message/FETCH_MESSAGES',
    VIEWED_MESSAGE = '@@message/VIEWED_MESSAGE',
    REMOVE_MESSAGE = '@@message/REMOVE_MESSAGE',
    SEND_MESSAGE = '@@message/SEND_MESSAGE',
    FETCHED_MESSAGES = '@@message/FETCHED_MESSAGES',

    CREATE_API = '@@api/CREATE',
    CREATED_API = '@@api/CREATED',
    REVOKE_API = '@@api/REVOKE',

    SOCKET_LISTENMESSAGE = '@@message/SOCKET_LISTENMESSAGE',
    SOCKET_LISTENMESSAGE_RECEIVED = '@@message/SOCKET_LISTENMESSAGE_RECEIVED',

    FETCH_SELLER = '@@seller/FETCH',
    FETCHED_SELLER = '@@seller/FETCHED',

    FETCH_EMAILTEMPLATES = '@@email/FETCH',
    FETCHED_EMAILTEMPLATES = '@@email/FETCHED',

    VALIDATE_PROMO = '@@coupon/VALIDATE',
    VALIDATED_PROMO = '@@coupon/VALIDATED',
}

export interface UserState {
    readonly loading: boolean;
    readonly user: IUser;
    readonly registerPromo?: RegisterPromoCode;
    readonly seller?: IUser;
    readonly order: IOrder;
    readonly rates: ICurrency;
    readonly curRate: string;
    readonly curRateValue: number;
    readonly errors?: string;
    readonly loggedIn: boolean;
    readonly apiKey?: string;
    readonly apiKeyCreateDate?: Date;
    readonly emailTemplates?: IEmailTemplate[];
}
