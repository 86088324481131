import { Reducer } from 'redux';
import { ICategory } from '../../types/Category';
import { IEffect, IFilteredPosts, IPost, ISearchPostRequest, ISetting, IPostFilterKind, ColorScheme } from '../../types/Post';
import { PostActionTypes, PostState } from './types';
import { IFilter } from '../../types/Filter';
import { Survey, Participator } from '../../types/Survey';
import moment from 'moment';
import { isDarkMode } from '../../constants';

// Type-safe initialState!
const initialState: PostState = {
    searchRequest: {
        filter_name: 'about_to_end',
        // price: { min: 0, max: 9999999 },
        filterKinds: [] as IPostFilterKind[],
    } as ISearchPostRequest,
    settings: {} as ISetting,
    effects: [] as IEffect[],
    filters: [] as IFilter[],
    categories: [] as ICategory[],
    allCategories: [] as ICategory[],
    posts: [] as IPost[],
    userPosts: [] as IPost[],
    currentPost: {} as IPost,
    currentPostView: {} as IPost,
    currentPostAdd: {} as IPost,
    totalPostsCount: 0,
    survey: {} as Survey,
    winners: [] as Participator[],
    errors: undefined,
    loading: true,
    coupon: undefined,
    updaterStarted: false,
    gridMode: 'grid',
    isDarkMode: isDarkMode,
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<PostState> = (state = initialState, action) => {
    switch (action.type) {
        case PostActionTypes.FETCH_POSTS:
        // case PostActionTypes.SAVE_POST:

        case PostActionTypes.DEPOSIT:
        case PostActionTypes.FETCH_USERPOSTS:
        case PostActionTypes.FETCH_FILTERS:
        case PostActionTypes.MAKE_BID:
        case PostActionTypes.FETCH_EFFECTS:
        case PostActionTypes.FETCH_SETTINGS:
        case PostActionTypes.FETCH_SURVEY:
        case PostActionTypes.FETCH_WINNERS:
        case PostActionTypes.VOTE:
        case PostActionTypes.VALIDATE_COUPON:
        // case PostActionTypes.FETCH_QUESTIONS:
        // case PostActionTypes.ASK_QUESTIONS:
        // case PostActionTypes.EDIT_QUESTIONS:
        case PostActionTypes.FETCH_BULK_POST: {
            return {
                ...state,
                loading: true,
                coupon: undefined,
                bulkPosts: undefined,
                winners: [] as Participator[],
                survey: {} as Survey,
            };
        }
        case PostActionTypes.SET_GRIDMODE: {
            return { ...state, gridMode: action.payload };
        }
        case PostActionTypes.FETCH_POST: {
            return { ...state, loading: true };
        }
        case PostActionTypes.SAVE_POST:
        case PostActionTypes.UPDATE_POST: {
            return { ...state, loading: true, uploadingData: true };
        }
        case PostActionTypes.PUBLISH_POST: {
            return { ...state, loading: true, publishingPost: true };
        }
        case PostActionTypes.FETCHED_BULK_POST: {
            // console.log('bulks', action.payload);
            return { ...state, bulkPosts: action.payload };
        }
        case PostActionTypes.FETCH_SUCCESS: {
            // console.log(action.type, action.payload)
            return {
                ...state,
                loading: false,
                categories: action.payload.categories,
                allCategories: action.payload.allCategories,
            };
        }
        case PostActionTypes.FETCHED_SURVEY: {
            // console.log("FFFFF_userpost", action.payload);
            return { ...state, loading: false, survey: action.payload };
        }

        case PostActionTypes.VALIDATED_COUPON: {
            // console.log("FFFFF_userpost", action.payload);
            return { ...state, loading: false, coupon: action.payload };
        }

        case PostActionTypes.AIRPORT_FOUND: {
            // console.log("FFFFF_userpost", action.payload);
            return { ...state, loading: false, airports: action.payload };
        }
        case PostActionTypes.FETCHED_WINNERS: {
            // console.log("FFFFF_userpost", action.payload);
            return { ...state, loading: false, winners: action.payload };
        }
        case PostActionTypes.FETCHED_USERPOST: {
            // console.log("FFFFF_userpost", action.payload);
            return { ...state, loading: false, currentPostAdd: action.payload };
        }
        case PostActionTypes.UPDATED_SEARCHREQUEST: {
            // console.log("FFFFF_userpost", action.payload);
            return { ...state, loading: false, searchRequest: Object.assign({}, action.payload) };
        }
        case PostActionTypes.FETCHED_POST: {
            // console.log("FFFFF", action.payload);
            return {
                ...state,
                loading: false,
                uploadingData: false,
                publishingPost: false,
                currentPost: action.payload,
            };
        }
        case PostActionTypes.FETCHED_SAVEDPOST: {
            // console.log("FFFFF", action.payload);
            return {
                ...state,
                loading: false,
                uploadingData: false,
                publishingPost: false,
                currentPostAdd: Object.assign({}, action.payload),
            };
        }
        case PostActionTypes.FETCHED_EFFECTS: {
            const effects = action.payload as IEffect[];
            effects.push({ name: 'None' } as IEffect);

            return { ...state, loading: false, effects: action.payload };
        }
        case PostActionTypes.FETCHED_SETTINGS: {
            const settings = Object.assign([], action.payload);
            const currentScheme = parseInt(settings['current_scheme']);
            const curScheme: ColorScheme = Object.assign({}, JSON.parse(settings['color_schemes'])[currentScheme]);
            
            return {
                ...state,
                loading: false,
                settings: settings,
                colorScheme: curScheme,
            };
        }
        case PostActionTypes.UPDATE_DARKMODE: {
            return {...state, isDarkMode: action.payload};
        }
        case PostActionTypes.FETCHED_FILTERS: {
            return { ...state, loading: false, filters: action.payload };
        }
        case PostActionTypes.FETCHED_POSTVIEW: {
            return { ...state, loading: false, currentPostView: action.payload };
        }
        case PostActionTypes.SOCKET_LISTENPOST_RECEIVED: {
            const curPost = Object.assign({}, state.currentPostView);
            const updatedPost = action.payload as IPost;
            const curId = curPost._id || curPost.id;
            const updId = updatedPost._id || updatedPost.id;
            // console.log(curId, updId);
            if (curId !== updId) return { ...state };
            curPost.is_payed = updatedPost.is_payed;
            curPost.is_published = updatedPost.is_published;
            curPost.is_started = updatedPost.is_started;
            curPost.last_bid = updatedPost.last_bid;
            curPost.long_description = updatedPost.long_description;
            curPost.deadline = updatedPost.deadline;
            curPost.publishedAt = updatedPost.publishedAt;
            curPost.status = updatedPost.status;
            curPost.title = updatedPost.title;
            curPost.participatorsCounter = updatedPost.participatorsCounter;
            curPost.top_bidder = updatedPost.top_bidder ? updatedPost.top_bidder : curPost.top_bidder;
            curPost.updatedAt = updatedPost.updatedAt;
            curPost.bids = updatedPost.bids ? updatedPost.bids : curPost.bids;
            curPost.bidders = updatedPost.bidders ? updatedPost.bidders : curPost.bidders;
            // console.log("post updated", curPost);
            return { ...state, currentPostView: curPost };
        }

        case PostActionTypes.SOCKET_LISTENNEW_RECEIVED: {
            const curPosts = Object.assign([] as IPost[], state.posts);
            const updatedPosts = action.payload as IPost[];
            let isUpdated = false;
            if (state.searchRequest.filter_name === 'all' || state.searchRequest.filter_name === 'just_added')
                for (const updatedPost of updatedPosts) {
                    const curPostIndex = curPosts.findIndex((p) => p._id === (updatedPost.id || updatedPost._id));
                    isUpdated = true;
                    if (curPostIndex >= 0) {
                        const curPost = curPosts[curPostIndex];
                        curPost.is_payed = updatedPost.is_payed;
                        curPost.is_published = updatedPost.is_published;
                        curPost.is_started = updatedPost.is_started;
                        curPost.last_bid = updatedPost.last_bid;
                        curPost.long_description = updatedPost.long_description;
                        curPost.deadline = updatedPost.deadline;
                        curPost.publishedAt = updatedPost.publishedAt;
                        curPost.status = updatedPost.status;
                        curPost.participatorsCounter = updatedPost.participatorsCounter;
                        curPost.title = updatedPost.title;
                        curPost.top_bidder = updatedPost.top_bidder ? updatedPost.top_bidder : curPost.top_bidder;
                        curPost.updatedAt = updatedPost.updatedAt;
                        curPost.bids = updatedPost.bids ? updatedPost.bids : curPost.bids;
                        curPost.bidders = updatedPost.bidders ? updatedPost.bidders : curPost.bidders;
                    } else curPosts.push(updatedPost);
                }
            if (isUpdated) return { ...state, posts: curPosts };
            else return { ...state };
        }

        case PostActionTypes.BID_SUCCESS: {
            const biddedPost = action.payload as IPost;
            if (!biddedPost._id) biddedPost._id = biddedPost.id;
            else if (!biddedPost.id) biddedPost.id = biddedPost._id;

            let posts = state.posts;
            let userPosts = state.userPosts;
            const currentPost = state.currentPost;
            const currentPostView = state.currentPostView;
            if (posts) {
                posts = posts.map((o) => {
                    if (o._id === biddedPost.id) return biddedPost;
                    return o;
                });
            }
            if (userPosts) {
                userPosts = userPosts.map((o) => {
                    if (o._id === biddedPost.id) return biddedPost;
                    return o;
                });
            }
            if (currentPost.others) {
                currentPost.others = currentPost.others.map((o) => {
                    if (o._id === biddedPost.id) return biddedPost;
                    return o;
                });
            }
            if (currentPostView.others) {
                currentPostView.others = currentPostView.others.map((o) => {
                    if (o._id === biddedPost.id) return biddedPost;
                    return o;
                });
            }
            if (currentPostView._id === biddedPost.id || currentPostView.id === biddedPost.id) {
                currentPostView.last_bid = biddedPost.last_bid;
                currentPostView.top_bidder = biddedPost.top_bidder;
            }
            return {
                ...state,
                loading: false,
                posts,
                userPosts,
                currentPost,
                currentPostView: Object.assign({}, currentPostView),
            };
        }
        case PostActionTypes.FETCHED_DEMO_POSTS: {
            const filteredPosts = action.payload as any;
            // const searchRequest = action.payload.searchRequest as ISearchPostRequest;
            // console.log('sse', searchRequest);
            // let posts = state.demoPosts;
            // if (filteredPosts.page === 1 || state.searchRequest !== searchRequest) {
            //     posts = filteredPosts.paginatedResults;
            //     console.log('RESET PAGE', posts);
            // } else {
            //     posts.push(...filteredPosts.paginatedResults);
            //     console.log('APPEND PAGE', posts);
            // }
            // if (state.totalPostsCount !== filteredPosts.totalCount)
            console.log(filteredPosts.paginatedResults);
            let demoPosts = filteredPosts.paginatedResults.paginatedResults;
            demoPosts = demoPosts.map((post: IPost) => {
                if (post.is_started && post.beed_time)
                    post.deadline = moment()
                        .add(post.beed_time.days, 'd')
                        .add(post.beed_time.hours, 'h')
                        .add(post.beed_time.minutes, 'm')
                        .add(post.beed_time.seconds, 's')
                        .toDate();
                else {
                    post.deadline = moment().add(7, 'd').toDate();
                }
                return post;
            });
            return {
                ...state,
                loading: false,
                demoPosts: demoPosts,
                // isLast: filteredPosts.isLast,
                // totalPostsCount: filteredPosts.totalCount,
                // searchRequest: searchRequest,
                // updaterStarted: true,
            };
            // return {
            //     ...state,
            //     loading: false,
            //     totalPostsCount: filteredPosts.totalCount,
            //     searchRequest: searchRequest,
            //     updaterStarted: true,
            // };
        }
        case PostActionTypes.FETCHED_POSTS: {
            const filteredPosts = action.payload.filteredPosts as IFilteredPosts;
            const searchRequest = action.payload.searchRequest as ISearchPostRequest;
            // console.log('sse', searchRequest);
            let posts = state.posts;
            let newPosts = filteredPosts.paginatedResults;
            const bulkGroups = posts.filter(post=>post.is_bulk).map(post=>post.bulk_group_id);
            if (filteredPosts.page === 1 || state.searchRequest !== searchRequest) {
                posts = newPosts;
                if (searchRequest.filter_name==='about_to_end') posts = posts.sort((p1, p2) => moment(p1.deadline).isAfter(p2.deadline) ? 1 : -1);
                console.log('RESET PAGE', posts);
            } else {
                if (bulkGroups.length > 0) newPosts = newPosts.filter(post=>!bulkGroups.includes(post.bulk_group_id))
                posts.push(...newPosts);
                console.log('APPEND PAGE', posts);
            }
            // if (state.totalPostsCount !== filteredPosts.totalCount)
            return {
                ...state,
                loading: false,
                posts: posts,
                isLast: filteredPosts.isLast,
                totalPostsCount: posts.length,
                searchRequest: searchRequest,
                updaterStarted: true,
            };
            // return {
            //     ...state,
            //     loading: false,
            //     totalPostsCount: filteredPosts.totalCount,
            //     searchRequest: searchRequest,
            //     updaterStarted: true,
            // };
        }
        case PostActionTypes.FETCHED_QUESTIONS: {
            return { ...state, loading: false, currentPostQuestions: action.payload };
        }
        case PostActionTypes.FETCHED_USERPOSTS: {
            return { ...state, loading: false, userPosts: action.payload };
        }
        case PostActionTypes.FETCH_ERROR: {
            console.log(action.payload);
            return { ...state, loading: false, errors: action.payload };
        }
        default: {
            return state;
        }
    }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as postReducer };
